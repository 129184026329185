import React from "react";
import InvoiceNumbersList from "../../../components/pages/client/invoice-number-list/rentalSchedule/rental-number-list/List.tsx";
import { ClientInvoiceNumbersList } from "../../../types.ts";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { GetInvoiceListApi } from "../../../apis.ts";
import { useNavigate } from "react-router-dom";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Invoices = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  const [clientInvoiceNumbers, setClientInvoiceNumbers] = React.useState<
    ClientInvoiceNumbersList[]
  >([]);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);
  const [mraLink, setMraLink] = React.useState<undefined | string>(undefined);

  const downloadDocument = React.useCallback(
    (name: string, url: string) => {
      navigate("/dashboard/pdfview", {
        state: { pdfUrl: url, pdfName: name },
      });
    },
    [navigate],
  );

  const handleAddInvoice = React.useCallback(() => {}, []);

  // Rental schedule get API call
  React.useEffect(() => {
    setInvoiceLoading(true);
    makeApiCall(GetInvoiceListApi())
      .then((response) => {
        if (response !== undefined) {
          setClientInvoiceNumbers(response?.data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setInvoiceLoading(false));
  }, [makeApiCall]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <InvoiceNumbersList
          invoiceNumbers={clientInvoiceNumbers}
          loading={invoiceLoading}
          downloadInvoice={downloadDocument}
          onUpdateInvoice={handleAddInvoice}
          mraUrl={mraLink ?? ""}
        />
      </div>
    </Main>
  );
};

export default Invoices;
