import { VendorClientType } from "@/src/types";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: VendorClientType;
  onViewOrders: (item: VendorClientType) => void;
}

export default function Action({ item, onViewOrders }: Props) {
  const navigate = useNavigate();

  const navigateToDetails = React.useCallback(() => {
    navigate(
      `/dashboard/purchasedetails?vendor_code=${encodeURIComponent(item?.["vendor_code"])}&vendor_name=${encodeURIComponent(item?.["vendor_name"])}`,
    );
  }, [item, navigate]);

  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <button
        onClick={navigateToDetails}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        View Purchases
      </button>
      {/* 
      <button
        onClick={handleDownloadQrCodes}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        QR Codes
      </button> */}
    </div>
  );
}
