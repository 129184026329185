import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DropdownType } from "@/src/types";
import Spacer from "../../../../../src/components/ui/Spacer";
import { Text } from "../../../../../src/components/ui/Text";
import { Dropdown } from "../../../../../src/components/ui/DropDown";
import Input from "../../../../../src/components/ui/Input";
import Row from "../../../../../src/components/ui/Row";
import Button from "../../../../../src/components/ui/Button";
import useApi from "../../../../../src/hooks/useApi";
import { VendorPanVerificationApi } from "../../../../../src/apis";
import { toast } from "react-toastify";

interface Props {
  onComplete: () => void;
}
const VendorPanVerification = ({ onComplete }: Props) => {
  const { makeApiCall } = useApi();

  const [loading, setLoading] = React.useState(false);
  const TypeData: DropdownType[] = React.useMemo(
    () => [
      { key: "corporate", value: "Private Limited" },
      { key: "corporate", value: "Proprietership" },
      { key: "corporate", value: "LLP" },
      { key: "ind", value: "Individual" },
    ],
    [],
  );

  const initial_values = {
    vendor_type: "corporate",
    pan: "",
    udyam_number: "",
    aadhar_number: "",
  };

  const validationSchema = Yup.object().shape({
    vendor_type: Yup.string().required("Entity type is required"),
    pan: Yup.string()
      .required("Pan number is required")
      .matches(
        /^[A-Za-z]{5}[0-9]{4}[A-Za-z0-9]$/,
        "PAN number should be in the format: XXXXX1234X",
      )
      .test(
        "pan-4th-digit",
        "For 'Individual', 4th character must be 'P'",
        (value, context) => {
          if (context.parent.vendor_type === "ind" && value) {
            return value[3] === "P";
          }
          return true;
        },
      ),
  });

  const handleSubmit = React.useCallback(
    ({
      vendor_type,
      pan,
      udyam_number,
      aadhar_number,
    }: typeof initial_values) => {
      setLoading(true);
      return makeApiCall(
        VendorPanVerificationApi(vendor_type, pan, udyam_number, aadhar_number),
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success(
              response.message ? response.message : "Pan verification success",
            );
            onComplete();
            return true;
          } else {
            toast.error("Something went wrong");
            return true;
          }
        })
        .catch((error) => {
          toast.error("Pan verification failed");
          console.error(error);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [makeApiCall, onComplete],
  );

  return (
    <section className="table-wrapper">
      <div className="py-8 px-4 mx-auto max-w-2xl">
        <Formik
          initialValues={initial_values}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Spacer orientation="horizontal" size="xs" />
              <Text>Entity Type</Text>
              <Spacer size="xs" />
              <Dropdown
                data={TypeData}
                key={`status-dropdown`}
                label="Entity Type"
                name="vendor_type"
              />
              <Spacer size="xs" />

              <Input label="Pan Number" placeholder="Enter pan" name="pan" />
              <Spacer size="xs" />

              <Input
                label="Udyam Registration (optional)"
                placeholder="Enter Registration"
                name="udyam_number"
              />
              <Spacer size="xs" />

              <Input
                label="Aadhar Number"
                placeholder="Enter Aadhar Number"
                name="aadhar_number"
                disabled={values.vendor_type !== "ind"}
              />
              <Spacer size="xs" />

              <Row justifyContent="center">
                <Button isLoading={loading} color="primary">
                  Submit
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default VendorPanVerification;
