import { GetVendorAddressListApi } from "../../../../../src/apis";
import Spacer from "../../../../../src/components/ui/Spacer";
import useApi from "../../../../../src/hooks/useApi";
import { VendorAddressType } from "../../../../../src/types";
import React from "react";
import VendorAddressList from "./VendorAddressList";

const VendorAddress = () => {
  const { makeApiCall } = useApi();

  const [vendorAddressList, setVendorAddressList] = React.useState<
    VendorAddressType[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetVendorAddressListApi())
      .then((response) => {
        if (response !== undefined) {
          setVendorAddressList(response.data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);
  return (
    <div className="text-black bg-pageBackground min-h-screen">
      <VendorAddressList
        loading={loading}
        data={
          vendorAddressList === null
            ? ([] as VendorAddressType[])
            : vendorAddressList
        }
      />
    </div>
  );
};

export default VendorAddress;
