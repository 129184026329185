import * as React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";
import PurchaseOrderDetails from "../../../../src/components/pages/client/vendor/PurchaseOrderDetails.tsx";

export default function PurchaseDetails() {
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <PurchaseOrderDetails />
      </div>
    </Main>
  );
}
