import * as React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { GetVendorClientApi } from "../../../apis.ts";
import ClientList from "../../../components/pages/epp/vendor/ClientList.tsx";
import { VendorClientListType } from "@/src/types.ts";
import Main from "../../../components/ui/sidebar/Main.tsx";
import Breadcrumb from "../../../../src/components/ui/Breadcrumb.tsx";

export default function Client() {
  const { makeApiCall } = useApi();

  const [vendorClientList, setVendorClientList] = React.useState<
    VendorClientListType[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  // Rental schedule get API call
  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetVendorClientApi())
      .then((response) => {
        if (response !== undefined) {
          setVendorClientList(response);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <Breadcrumb locations={[{ name: "Client", path: "/client" }]} />
        <ClientList
          loading={loading}
          data={
            vendorClientList === null
              ? ([] as VendorClientListType[])
              : vendorClientList
          }
        />
      </div>
    </Main>
  );
}
