import * as React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useApi from "../../../hooks/useApi.tsx";
import { AddEppVendorInventoryApi } from "../../../apis.ts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileInput from "../../../components/ui/FileInput.tsx";
import Button from "../../../components/ui/Button.tsx";
import Row from "../../../components/ui/Row.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";
import { CiSaveDown2 } from "react-icons/ci";
import { exportToExcel } from "../../../utils/utils.ts";

const INTIAL_VALUES = {
  vendor_inventory: null as File | null,
};

export default function AddInventory() {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    vendor_inventory: Yup.mixed().required("File is required"),
  });

  const downloadCSV = React.useCallback(() => {
    const headers = [
      {
        "Company Name": "",
        "Address Line 1": "",
        "Address Line 2": "",
        City: "",
        State: "",
        PIN: "",
        "GST Number": "",
      },
    ];
    return exportToExcel(headers, "address-example-csv");
  }, []);

  const handleSubmit = React.useCallback(
    ({ vendor_inventory }: typeof INTIAL_VALUES) => {
      return makeApiCall(AddEppVendorInventoryApi(vendor_inventory!))
        .then((response) => {
          navigate("/client/vendor-inventory");
          toast.success("Inventory added succesfully");
        })
        .catch(() => {
          toast.error("Inventory addition failed");
        });
    },
    [makeApiCall, navigate],
  );
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center w-[100%]">
            <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
              Add Vendor Inventory
            </p>
            <Button
              color="default"
              variant="bordered"
              startContent={<CiSaveDown2 size={18} />}
              className="font-roboto"
              size="sm"
              radius="sm"
              onClick={downloadCSV}
            >
              Download
            </Button>
          </div>
          <Spacer size="xs" />
          <Formik
            initialValues={INTIAL_VALUES}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form>
              <FileInput
                name="vendor_inventory"
                type="dropzone"
                accept=".csv"
              />
              <Spacer size="sm" />
              <Row justifyContent="center">
                <Button type="submit">Submit</Button>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </Main>
  );
}
