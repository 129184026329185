import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { firestore } from "../../lib/db";
import { addDoc, collection } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopNav from "../../components/TopNav";
const Postblog = () => {
  const [formData, setFormData] = useState({
    author: "",
    content: "",
    createdAt: "",
    deletedAt: "",
    header: "",
    image: "",
    metadata: "",
    slug: "",
    title: "",
    userId: "",
    description: "",
  });

  const notify = (text) => toast.success(text);

  const notifyfail = (text) => toast.error(text);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isFormValid =
    formData.author !== "" &&
    formData.title !== "" &&
    formData.content !== "" &&
    formData.createdAt !== "" &&
    formData.deletedAt !== "" &&
    formData.description !== "" &&
    formData.header !== "" &&
    formData.image !== "" &&
    formData.metadata !== "" &&
    formData.slug !== "";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid === true) {
      try {
        await addDoc(collection(firestore, "blogs"), formData);
        notify("Blog added successfully");
        setFormData({
          author: "",
          content: "",
          createdAt: "",
          deletedAt: "",
          header: "",
          image: "",
          metadata: "",
          slug: "",
          title: "",
          userId: "",
          description: "",
        });
      } catch (error) {
        notifyfail("Error occurred!!");

        console.error("Error adding document: ", error);
      }
    } else {
      notifyfail("Please fill the form!!");
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          reachus
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>
      <div className="container p-10">
        <TopNav
          bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
          title="Post blog"
          titledesc=""
          showreadmore={false}
        />
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Add input fields here */}

          <InputField
            name="author"
            value={formData.author}
            onChange={handleChange}
            placeholder="Author"
          />
          <InputField
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Title"
          />
          <InputField
            name="content"
            value={formData.content}
            onChange={handleChange}
            placeholder="Content"
            textarea
          />
          <p>Created At</p>
          <InputField
            name="createdAt"
            value={formData.createdAt}
            onChange={handleChange}
            placeholder="Created At"
            type="date"
          />
          <p>deleted At</p>

          <InputField
            name="deletedAt"
            value={formData.deletedAt}
            onChange={handleChange}
            placeholder="deletedAt At"
            type="date"
          />
          <InputField
            name="header"
            value={formData.header}
            onChange={handleChange}
            placeholder="Header"
          />
          <InputField
            name="image"
            value={formData.image}
            onChange={handleChange}
            placeholder="Image URL"
          />
          <InputField
            name="metadata"
            value={formData.metadata}
            onChange={handleChange}
            placeholder="Metadata"
          />
          <InputField
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            placeholder="Slug"
          />
          <InputField
            name="userId"
            value={formData.userId}
            onChange={handleChange}
            placeholder="User ID"
          />
          <InputField
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
          />

          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded "
          >
            Submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Postblog;

const InputField = ({
  name,
  value,
  onChange,
  placeholder,
  type = "text",
  textarea = false,
}) => {
  return textarea ? (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="block w-full p-2 border border-gray-300 rounded"
    />
  ) : (
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="block w-full p-2 border border-gray-300 rounded"
    />
  );
};
