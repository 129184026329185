import { VendorInvoiceListType } from "@/src/types";
import * as React from "react";

interface Props {
  item: VendorInvoiceListType;
}

export default function VendorInvoiceAction({ item }: Props) {
  const handleViewOrders = React.useCallback(() => {
    if (item["Invoice_PDF_URL__c"]) {
      window.open(item["Invoice_PDF_URL__c"], "_blank");
    }
  }, [item]);

  return (
    <div className="flex flex-row justify-center gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <button
        onClick={handleViewOrders}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        View Invoices
      </button>
      {/* <button
        onClick={navigateToDetails}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        Details
      </button> */}
    </div>
  );
}
