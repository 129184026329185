import * as React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spacer from "../../../../ui/Spacer.tsx";
import Row from "../../../../ui/Row.tsx";
import Breadcrumb from "../../../../ui/Breadcrumb.tsx";
import PoProductDetailList from "./po-details/PoProductDetailList.tsx";
import PoOrderDetailList from "./po-details/PoOrderDetailList.tsx";
import PoInvoiceList from "./invoice-upload/PoInvoiceList.tsx";

export default function PoOrderDetail() {
  const [selected, setSelected] = React.useState<React.Key>("order-details");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyCode = searchParams.get("company_code") ?? "";
  const companyName = searchParams.get("company_name") ?? "";
  const poNumber = searchParams.get("po_number") ?? "";

  const navigateToUploadProductDetails = React.useCallback(() => {
    return navigate(
      `/client/po-upload-product?company_name=${encodeURIComponent(companyName)}&company_code=${encodeURIComponent(companyCode)}&po_number=${encodeURIComponent(poNumber)}`,
    );
  }, [companyCode, companyName, navigate, poNumber]);
  const navigateToUploadinvoiceDetails = React.useCallback(() => {
    return navigate(
      `/client/po-upload-invoice?company_name=${encodeURIComponent(companyName)}&company_code=${encodeURIComponent(companyCode)}&po_number=${encodeURIComponent(poNumber)}`,
    );
  }, [companyCode, companyName, navigate, poNumber]);

  return (
    <div className="flex flex-col">
      <Breadcrumb
        locations={[
          { name: "View Orders", path: "/client/view-order-detail" },
          { name: poNumber, path: "/client/po-detail" },
        ]}
      />
      <Row justifyContent="space-between" alignItems="center">
        <p className="text-black text-lg leading-8 font-roboto font-normal">
          Asset Details
        </p>
      </Row>
      <Spacer size="xs" />
      <Tabs
        aria-label="Options"
        selectedKey={selected.toString()}
        onSelectionChange={setSelected}
      >
        <Tab key="order-details" title="Order Details">
          <PoOrderDetailList poNumber={poNumber} />
        </Tab>
        <Tab key="product-details" title="Product Details">
          <PoProductDetailList
            poNumber={poNumber}
            navigateToUploadProductDetails={navigateToUploadProductDetails}
          />
        </Tab>
        <Tab key="invoice-upload" title="Invoice">
          <PoInvoiceList
            poNumber={poNumber}
            companyCode={companyCode}
            navigateToUploadinvoiceDetails={navigateToUploadinvoiceDetails}
          />
        </Tab>
      </Tabs>
    </div>
  );
}
