import * as React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useToast from "../../../../src/hooks/useToast";
import UseApi from "../../../../src/hooks/useApi";
import Spacer from "../../../../src/components/ui/Spacer";
import Input from "../../../../src/components/ui/Input";
import { Button } from "@nextui-org/react";
import { CreateLevelApi, GetLevelApi } from "../../../../src/apis";

export default function ApprovalPolicy() {
  const [loading, setLoading] = React.useState(false);
  const { showToast } = useToast();
  const { makeApiCall } = UseApi();

  const [initialValues, setinitialValues] = React.useState({
    l1_start: "0",
    l1_end: "0",
    l2_start: "0",
    l2_end: "0",
    l3_start: "0",
    l3_end: "0",
    l4_start: "0",
    l4_end: "0",
    l5_start: "0",
    l5_end: "0",
  });

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetLevelApi())
      .then((response) => {
        if (response.approvalCodes !== undefined) {
          let tempVals = response?.approvalCodes;
          setinitialValues({
            l1_start: tempVals?.L1Start,
            l1_end: tempVals?.L1End,
            l2_start: tempVals?.L2Start,
            l2_end: tempVals?.L2End,
            l3_start: tempVals?.L3Start,
            l3_end: tempVals?.L3End,
            l4_start: tempVals?.L4Start,
            l4_end: tempVals?.L4End,
            l5_start: tempVals?.L5Start,
            l5_end: tempVals?.L5End,
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);
  const handleSubmit = React.useCallback(
    ({
      l1_start,
      l1_end,
      l2_start,
      l2_end,
      l3_start,
      l3_end,
      l4_start,
      l4_end,
      l5_start,
      l5_end,
    }: typeof initialValues) => {
      setLoading(true);
      return makeApiCall(
        CreateLevelApi(
          l1_start,
          l1_end,
          l2_start,
          l2_end,
          l3_start,
          l3_end,
          l4_start,
          l4_end,
          l5_start,
          l5_end,
        ),
      )
        .then(() => {
          showToast("Levels updated successfully", { type: "success" });
        })
        .catch(() => {
          showToast("Levels updation failed", { type: "error" });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [makeApiCall, showToast],
  );

  const validationSchema = Yup.object().shape({
    l1_start: Yup.string().required("Level 1 start is required"),
    l1_end: Yup.string().required("Level 1 end is required"),
    l2_start: Yup.string().required("Level 2 start is required"),
    l2_end: Yup.string().required("Level 2 end is required"),
    l3_start: Yup.string().required("Level 3 start is required"),
    l3_end: Yup.string().required("Level 3 end is required"),
    l4_start: Yup.string().required("Level 4 start is required"),
    l4_end: Yup.string().required("Level 4 end is required"),
    l5_start: Yup.string().required("Level 5 start is required"),
    l5_end: Yup.string().required("Level 5 end is required"),
  });
  return (
    <>
      <div>
        <section className="bg-white pt-5 pl-5">
          <p className="text-black text-base leading-8 font-roboto font-normal">
            Approval Code
          </p>
          <p className="text-black text-sm  font-roboto font-light">
            Setup a rental based approval policy to referencing maximum total
            rental amount criteria by Employee. Below you can freeze the rental
            ranges for various levels, with are mapped to the respective
            employee, enabling easier decision making at the time of approvals
          </p>
          <div className="py-8 max-w-2xl">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              <Form>
                <div className="grid grid-cols-3 gap-4 mb-4 justify-items-center">
                  <p className="text-black font-roboto font-normal text-base">
                    Hierarchy Code
                  </p>
                  <p className="text-black font-roboto font-normal text-base">
                    Starting Range
                  </p>
                  <p className="text-black font-roboto font-normal text-base">
                    Ending Range
                  </p>
                </div>

                {/* Level Rows */}
                {["l1", "l2", "l3", "l4", "l5"].map((level) => (
                  <div
                    key={level}
                    className="grid grid-cols-3 gap-4 mb-4 justify-items-center"
                  >
                    <p className="text-black font-roboto font-normal text-base">
                      {level.toUpperCase()}
                    </p>
                    <Input
                      placeholder={`${level.toUpperCase()} start`}
                      name={`${level}_start`}
                      className="mx-2 text-center"
                    />
                    <Input
                      placeholder={`${level.toUpperCase()} end`}
                      name={`${level}_end`}
                      className="text-center"
                    />
                  </div>
                ))}

                <Spacer size="xs" />

                <div className="flex justify-center items-center mt-5">
                  <Button color="primary" type="submit" isLoading={loading}>
                    Save
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </section>
      </div>
    </>
  );
}
