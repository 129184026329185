import { Tab, Tabs } from "@nextui-org/react";
import Main from "../../../../src/components/ui/sidebar/Main";
import Spacer from "../../../../src/components/ui/Spacer";
import React from "react";
import VendorPanVerification from "../../../../src/components/pages/epp/vendor/VendorPanVerification";
import BankVerification from "../../../../src/components/pages/epp/vendor/BankVerification";
import VendorAddress from "../../../../src/components/pages/epp/vendor/VendorAddress";
import useApi from "../../../../src/hooks/useApi";
import { VendorInfoType } from "../../../../src/types";
import { GetVendorInfoApi } from "../../../../src/apis";
import Breadcrumb from "../../../../src/components/ui/Breadcrumb";

const VendorProfile = () => {
  const [selected, setSelected] = React.useState<React.Key>("pan-details"); // Start with the Pan tab selected

  const { makeApiCall } = useApi();

  const [vendorInfo, setVendorVendorInfo] =
    React.useState<VendorInfoType | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetVendorInfoApi())
      .then((response) => {
        if (response !== undefined) {
          setVendorVendorInfo(response.data);

          const vendor = response.data; // Assuming first vendor data is used

          if (!vendor?.is_pan_verified) {
            setSelected("pan-details"); // If PAN is not verified, select "Pan"
          } else if (!vendor?.is_bank_verified) {
            setSelected("bank-details"); // If Bank is not verified, select "Bank Details"
          } else {
            setSelected("address-details"); // If both are verified, select "Address Details"
          }
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  // Handle pan verification completion
  const handlePanVerificationComplete = () => {
    setSelected("bank-details"); // Activate Bank tab after Pan verification
  };

  // Handle bank verification completion
  const handleBankVerificationComplete = () => {
    setSelected("address-details"); // Activate Address tab after Bank verification
  };

  // Check if vendorInfo is loaded and if the values for Pan and Bank verification are available
  const isPanVerified = vendorInfo?.is_pan_verified ?? false;
  const isBankVerified = vendorInfo?.is_bank_verified ?? false;

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />

        <Breadcrumb
          locations={[{ name: "Profile", path: "/client/vendor-profile" }]}
        />
        <Spacer size="sm" />
        <p className="text-black text-lg leading-8 font-roboto font-normal">
          Profile
        </p>

        <div>
          <Tabs
            aria-label="Options"
            selectedKey={selected.toString()}
            onSelectionChange={setSelected}
          >
            <Tab
              key="pan-details"
              title="Pan"
              isDisabled={isPanVerified} // Disable the Pan tab if Pan is verified
            >
              <VendorPanVerification
                onComplete={handlePanVerificationComplete}
              />
            </Tab>
            <Tab
              key="bank-details"
              title="Bank Details"
              isDisabled={isBankVerified || !isPanVerified} // Disable Bank tab if Bank is verified or PAN is not verified
            >
              <BankVerification onComplete={handleBankVerificationComplete} />
            </Tab>
            <Tab key="address-details" title="Address Details">
              <VendorAddress />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Main>
  );
};

export default VendorProfile;
