import { GetInvoicsSalesforceCompanyWiseInvoicesByVendorApi } from "../../../apis.ts";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi.tsx";
import { VendorInvoiceListType } from "../../../types.ts";
import VendorPoinvoicesList from "../../../components/pages/epp/vendor/vendor-poinvoices/List.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";

const VendorInvoice: React.FC = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [invoices, setInvoice] = React.useState<VendorInvoiceListType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const navigateToAddAddress = React.useCallback(() => {
    navigate("/dashboard/add-inventory");
  }, [navigate]);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetInvoicsSalesforceCompanyWiseInvoicesByVendorApi())
      .then((response) => {
        setLoading(false);
        console.log(response, "Response if invoice s by vendor ciode");
        if (response && response.length > 0) {
          const extractedInvoices = response.flatMap((item: any) =>
            item.Vendor_Invoices__r.map((invoice: any) => ({
              PO_Number: item["PO Number"],
              Invoice_PDF_URL__c: invoice.Invoice_PDF_URL__c,
              Invoice_Remarks__c: invoice.Invoice_Remarks__c,
              Invoice_Status__c: invoice.Invoice_Status__c,
              Invoice_Type__c: invoice.Invoice_Type__c,
            })),
          );
          setInvoice(extractedInvoices);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [makeApiCall]);

  return (
    <>
      <Main>
        <div className="text-black bg-pageBackground px-10 min-h-screen">
          <VendorPoinvoicesList
            invoices={invoices}
            loading={loading}
            navigateToAddAddress={navigateToAddAddress}
          />
        </div>
      </Main>
    </>
  );
};

export default VendorInvoice;
