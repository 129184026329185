import * as React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import Row from "../../../../../src/components/ui/Row";
import Spacer from "../../../../../src/components/ui/Spacer";
import PurchaseOrderDetailList from "./PurchaseOrderDetailList";
import PurchaseInvoiceList from "./PurchaseInvoiceList";

export default function PurchaseOrderDetails() {
  const [selected, setSelected] = React.useState<React.Key>("order-details");

  return (
    <div className="flex flex-col">
      <Row justifyContent="space-between" alignItems="center">
        <p className="text-black text-lg leading-8 font-roboto font-normal">
          Purchase Details
        </p>
      </Row>
      <Spacer size="xs" />
      <Tabs
        aria-label="Options"
        selectedKey={selected.toString()}
        onSelectionChange={setSelected}
      >
        <Tab key="order-details" title="Order Details">
          <PurchaseOrderDetailList />
        </Tab>
        <Tab key="invoice-upload" title="Invoice">
          <PurchaseInvoiceList />
        </Tab>
      </Tabs>
    </div>
  );
}
