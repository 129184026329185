import * as React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  SortDescriptor,
} from "@nextui-org/react";
import { useSearchParams } from "react-router-dom";
import { VendorOrderListType } from "../../../../../src/types";
import useApi from "../../../../../src/hooks/useApi";
import { GetPurchasesSalesforceCompanyWiseOrdersApi } from "../../../../../src/apis";
import VendorAction from "./VendorAction";
import Spacer from "../../../../../src/components/ui/Spacer";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Po Number",
    key: "po_number",
  },
  {
    name: "Created Date",
    key: "created_date",
  },
  {
    name: "Company",
    key: "client_id",
  },
  {
    name: "Total Amount",
    key: "total_amount",
  },
  {
    name: "Total Quantity",
    key: "total_quantity",
  },
  {
    name: "Action",
    key: "action",
  },
];

export default function PurchaseOrderDetailList() {
  const [orders, setOrders] = React.useState<VendorOrderListType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filterValue] = React.useState("");
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "employee_name",
    direction: "ascending",
  });

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...orders];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((orders) =>
        orders["PO Number"].toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filteredUsers;
  }, [orders, filterValue, hasSearchFilter]);

  const { makeApiCall } = useApi();

  const [searchParams] = useSearchParams();
  const vendorCode = searchParams.get("vendor_code") ?? "";

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetPurchasesSalesforceCompanyWiseOrdersApi(vendorCode))
      .then((response) => {
        if (response !== undefined) {
          setOrders(response);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall, vendorCode]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a: VendorOrderListType, b: VendorOrderListType) => {
      const first = a[
        sortDescriptor.column as keyof VendorOrderListType
      ] as number;
      const second = b[
        sortDescriptor.column as keyof VendorOrderListType
      ] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [],
  );

  const pages = React.useMemo(() => {
    if (orders?.length === 0) {
      return 1;
    }
    return Math.ceil((orders?.length ?? 1) / rowsPerPage);
  }, [orders?.length, rowsPerPage]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  const renderCell = React.useCallback(
    (item: VendorOrderListType, columnKey: React.Key) => {
      const index = sortedItems
        ?.map((object) => object["PO Number"])
        .indexOf(item["PO Number"]);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "po_number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["PO Number"]}
              </p>
            </div>
          );
        case "created_date":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["CreatedDate"]}
              </p>
            </div>
          );
        case "client_id":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Client ID"]}
              </p>
            </div>
          );
        case "total_amount":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Total Amount"]}
              </p>
            </div>
          );
        case "total_quantity":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Total Quantity"]}
              </p>
            </div>
          );

        case "action":
          return <VendorAction item={item} />;
      }
    },
    [sortedItems],
  );

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <div className="flex flex-col">
      <p className="text-black text-lg leading-8 font-roboto font-normal">
        Purchase Orders
      </p>
      <Spacer size="xs" />
      <Table
        selectionMode="single"
        classNames={classNames}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item["PO Number"]}`}
              className="cursor-pointer h-12 "
            >
              {(columnKey) => (
                <TableCell className="text-center">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
