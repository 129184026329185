import { GetEppVendorPowiseInventoryApi } from "../../../apis.ts";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi.tsx";
import { VendorInventoryType } from "../../../types.ts";
import VendorInventoryList from "../../../../src/components/pages/epp/vendor/vendor-inventory/List.tsx";
import Main from "../../../../src/components/ui/sidebar/Main.tsx";

const VendorInventory: React.FC = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [inventory, setInventory] = React.useState<VendorInventoryType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const navigateToAddAddress = React.useCallback(() => {
    navigate("/dashboard/add-inventory");
  }, [navigate]);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppVendorPowiseInventoryApi(""))
      .then((response) => {
        setLoading(false);
        if (response !== undefined) {
          setInventory(response?.inventory);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [makeApiCall]);

  return (
    <>
      <Main>
        <div className="text-black bg-pageBackground px-10 min-h-screen">
          <VendorInventoryList
            inventory={inventory}
            loading={loading}
            navigateToAddAddress={navigateToAddAddress}
          />
        </div>
      </Main>
    </>
  );
};

export default VendorInventory;
