import { VendorOrderListType } from "@/src/types";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: VendorOrderListType;
}

export default function VendorAction({ item }: Props) {
  const navigate = useNavigate();

  const handleViewOrders = React.useCallback(() => {
    if (item["PO PDF-Link"]) {
      window.open(item["PO PDF-Link"], "_blank");
    }
  }, [item]);
  const navigateToDetails = React.useCallback(() => {
    navigate(
      `/client/po-detail?company_code=${encodeURIComponent(item?.["Client ID"])}&po_number=${encodeURIComponent(item?.["PO Number"])}`,
    );
  }, [item, navigate]);

  return (
    <div className="flex flex-row justify-center gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <button
        onClick={handleViewOrders}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        Download PO
      </button>
      {/* <button
        onClick={navigateToDetails}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        Details
      </button> */}
    </div>
  );
}
