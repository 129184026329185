import * as React from "react";
import { Selection, Select as NSelect, SelectItem } from "@nextui-org/react";
import { DropdownType, EmployeeDirectoryType } from "../../../../types";

interface Props {
  array: DropdownType[];
  selectedValue?: EmployeeDirectoryType | undefined;
  selectedKeys: Selection;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectionChange: (keys: Selection) => any;
  ariaLabel?: string; // Added for accessibility
}

export default function Select({
  array,
  selectedValue,
  selectedKeys,
  onSelectionChange,
  ariaLabel = "Select an option", // Default aria-label
}: Props) {
  // Ensure selectedKeys are valid based on the array keys
  const validKeys = new Set(array.map((item) => item.key));
  const filteredSelectedKeys = [...selectedKeys]?.filter((key) =>
    validKeys.has(key as string),
  );

  return (
    <NSelect
      color="secondary"
      selectionMode="single"
      onSelectionChange={onSelectionChange}
      selectedKeys={filteredSelectedKeys}
      className="min-w-36"
      aria-label={ariaLabel} // Added for accessibility
    >
      {array?.map((item) => (
        <SelectItem key={item.key}>{item.value}</SelectItem>
      ))}
    </NSelect>
  );
}
