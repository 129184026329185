import * as React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  SortDescriptor,
  Chip,
} from "@nextui-org/react";
import { useSearchParams } from "react-router-dom";
import { VendorInvoiceListType } from "../../../../types";
import useApi from "../../../../hooks/useApi";
import { GetPurchasesSalesforceCompanyWiseInvoicesApi } from "../../../../apis";
import Spacer from "../../../ui/Spacer";
import VendorInvoiceAction from "./VendorInvoiceAction";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Po Number",
    key: "PO_Number",
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
  },
  {
    name: "Type",
    key: "Invoice_Type__c",
  },
  {
    name: "Invoice Remarks",
    key: "Invoice_Remarks__c",
  },

  {
    name: "Status",
    key: "Invoice_Status__c",
  },
  {
    name: "Action",
    key: "action",
  },
];

export default function PurchaseInvoiceList() {
  const [invoices, setInvoices] = React.useState<VendorInvoiceListType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filterValue] = React.useState("");
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "employee_name",
    direction: "ascending",
  });

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...invoices];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((invoices) =>
        invoices["PO_Number"].toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filteredUsers;
  }, [invoices, filterValue, hasSearchFilter]);

  const { makeApiCall } = useApi();

  const [searchParams] = useSearchParams();
  const vendorCode = searchParams.get("vendor_code") ?? "";

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetPurchasesSalesforceCompanyWiseInvoicesApi(vendorCode))
      .then((response) => {
        console.log(response, "Response of invoices api");
        if (response && response.length > 0) {
          const extractedInvoices = response.flatMap((item: any) =>
            item.Vendor_Invoices__r.map((invoice: any) => ({
              PO_Number: item["PO Number"],
              Invoice_PDF_URL__c: invoice.Invoice_PDF_URL__c,
              Invoice_Remarks__c: invoice.Invoice_Remarks__c,
              Invoice_Status__c: invoice.Invoice_Status__c,
              Invoice_Type__c: invoice.Invoice_Type__c,
            })),
          );
          setInvoices(extractedInvoices);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall, vendorCode]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort(
      (a: VendorInvoiceListType, b: VendorInvoiceListType) => {
        const first = a[
          sortDescriptor.column as keyof VendorInvoiceListType
        ] as string;
        const second = b[
          sortDescriptor.column as keyof VendorInvoiceListType
        ] as string;
        const cmp = first < second ? -1 : first > second ? 1 : 0;

        return sortDescriptor.direction === "descending" ? -cmp : cmp;
      },
    );
  }, [sortDescriptor, items]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [],
  );

  const pages = React.useMemo(() => {
    if (invoices?.length === 0) {
      return 1;
    }
    return Math.ceil((invoices?.length ?? 1) / rowsPerPage);
  }, [invoices?.length, rowsPerPage]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  const renderStatus = React.useCallback((item: VendorInvoiceListType) => {
    switch (item?.Invoice_Status__c) {
      case "Pending Approval from Connect":
        return (
          <Chip variant="bordered" color="warning">
            Pending Approval from Connect
          </Chip>
        );
      case "Approved from Connect":
        return (
          <Chip variant="bordered" color="success">
            Approved from Connect
          </Chip>
        );
      case "Rejected":
        return (
          <Chip variant="bordered" color="danger">
            Rejected
          </Chip>
        );
      default:
        return (
          <Chip variant="bordered" color="default">
            -
          </Chip>
        );
    }
  }, []);

  const renderCell = React.useCallback(
    (item: VendorInvoiceListType, columnKey: React.Key) => {
      const index = sortedItems
        ?.map((object) => object["PO_Number"])
        .indexOf(item["PO_Number"]);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "PO_Number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["PO_Number"]}
              </p>
            </div>
          );
        case "invoice_number":
          const invoiceUrl = item["Invoice_PDF_URL__c"];
          const invoiceName = invoiceUrl ? invoiceUrl.split("/").pop() : "-";
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{invoiceName}</p>
            </div>
          );
        case "Invoice_Type__c":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Invoice_Type__c"]}
              </p>
            </div>
          );
        case "Invoice_Status__c":
          return renderStatus(item);

        case "Invoice_Remarks__c":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Invoice_Remarks__c"]}
              </p>
            </div>
          );

        case "action":
          return <VendorInvoiceAction item={item} />;
      }
    },
    [renderStatus, sortedItems],
  );

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <div className="flex flex-col">
      <p className="text-black text-lg leading-8 font-roboto font-normal">
        Purchase Invoices
      </p>
      <Spacer size="xs" />
      <Table
        selectionMode="single"
        classNames={classNames}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item["PO_Number"]}`}
              className="cursor-pointer h-12 "
            >
              {(columnKey) => (
                <TableCell className="text-center">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
