import * as React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  Input,
  Button,
  SortDescriptor,
  Chip,
} from "@nextui-org/react";
import {
  SelectType,
  VendorInvoiceListType,
} from "../../../../../../../src/types";
import useApi from "../../../../../../hooks/useApi.tsx";
import { GetPurchasesSalesforceCompanyWiseInvoicesByoApi } from "../../../../../../apis.ts";
import { CiSearch } from "react-icons/ci";
import Row from "../../../../../ui/Row.tsx";
import Select from "../../../../../common/Select.tsx";
import VendorInvoiceAction from "./VendorInvoiceAction.tsx";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Po Number",
    key: "PO_Number",
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
  },
  {
    name: "Type",
    key: "Invoice_Type__c",
  },
  {
    name: "Invoice Remarks",
    key: "Invoice_Remarks__c",
  },

  {
    name: "Status",
    key: "Invoice_Status__c",
  },
  {
    name: "Action",
    key: "action",
  },
];

interface Props {
  poNumber: string;
  companyCode: string;
  navigateToUploadinvoiceDetails: () => void;
}
export default function PoInvoiceList({
  poNumber,
  companyCode,
  navigateToUploadinvoiceDetails,
}: Props) {
  const [invoices, setInvoices] = React.useState<VendorInvoiceListType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [selectedState, setSelectedState] = React.useState("all");
  const [showFilter, setShowFilter] = React.useState(false);
  const [dropdownFilter, setDropdownFilters] = React.useState<SelectType[]>([]);
  const [tempSelectedState, setTempSelectedState] = React.useState("all");

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "name",
    direction: "ascending",
  });

  const { makeApiCall } = useApi();

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(
      GetPurchasesSalesforceCompanyWiseInvoicesByoApi(companyCode, poNumber),
    )
      .then((response) => {
        console.log(response, "reposnd of po ai in vendor tapep");
        if (response && response.length > 0) {
          const extractedInvoices = response.flatMap((item: any) =>
            item.Vendor_Invoices__r.map((invoice: any) => ({
              PO_Number: item["PO Number"],
              Invoice_PDF_URL__c: invoice.Invoice_PDF_URL__c,
              Invoice_Remarks__c: invoice.Invoice_Remarks__c,
              Invoice_Status__c: invoice.Invoice_Status__c,
              Invoice_Type__c: invoice.Invoice_Type__c,
            })),
          );
          setInvoices(extractedInvoices);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [companyCode, makeApiCall, poNumber]);
  React.useEffect(() => {
    const dropdownOptionsData: SelectType[] = invoices
      ?.map((item: VendorInvoiceListType) => ({
        value: item.PO_Number,
        label: item.PO_Number,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownOptionsData.unshift({ value: "all", label: "All" });

    setDropdownFilters(dropdownOptionsData);
  }, [invoices]);

  const pages = React.useMemo(() => {
    if ((invoices?.length ?? 0) === 0) {
      return 1;
    }
    return Math.ceil((invoices?.length ?? 1) / rowsPerPage);
  }, [invoices?.length, rowsPerPage]);

  const hasSearchFilter = Boolean(filterValue);
  const [page, setPage] = React.useState(1);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...invoices];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.PO_Number.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.Invoice_Type__c.toLowerCase().includes(
            filterValue.toLowerCase(),
          ),
      );
    }

    if (selectedState !== "all" && selectedState) {
      filteredUsers = filteredUsers.filter(
        (user) => user.PO_Number === selectedState,
      );
    }
    return filteredUsers;
  }, [filterValue, hasSearchFilter, invoices, selectedState]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const handleStateSelect = (value: string) => {
    setTempSelectedState(value);
  };

  const sortedItems = React.useMemo(() => {
    return [...items].sort(
      (a: VendorInvoiceListType, b: VendorInvoiceListType) => {
        const first = a[sortDescriptor.column as keyof VendorInvoiceListType];
        const second = b[sortDescriptor.column as keyof VendorInvoiceListType];

        let cmp: number;

        if (typeof first === "number" && typeof second === "number") {
          cmp = first < second ? -1 : first > second ? 1 : 0;
        } else if (typeof first === "string" && typeof second === "string") {
          cmp = first.localeCompare(second);
        } else {
          cmp = 0;
        }
        return sortDescriptor.direction === "descending" ? -cmp : cmp;
      },
    );
  }, [sortDescriptor, items]);

  const renderStatus = React.useCallback((item: VendorInvoiceListType) => {
    switch (item?.Invoice_Status__c) {
      case "Pending Approval from Connect":
        return (
          <Chip variant="bordered" color="warning">
            Pending Approval from Connect
          </Chip>
        );
      case "Approved from Connect":
        return (
          <Chip variant="bordered" color="success">
            Approved from Connect
          </Chip>
        );
      case "Rejected":
        return (
          <Chip variant="bordered" color="danger">
            Rejected
          </Chip>
        );
      default:
        return (
          <Chip variant="bordered" color="default">
            -
          </Chip>
        );
    }
  }, []);

  const renderCell = React.useCallback(
    (item: VendorInvoiceListType, columnKey: React.Key) => {
      const index = sortedItems
        ?.map((object) => object["PO_Number"])
        .indexOf(item["PO_Number"]);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "PO_Number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["PO_Number"]}
              </p>
            </div>
          );
        case "invoice_number":
          const invoiceUrl = item["Invoice_PDF_URL__c"];
          const invoiceName = invoiceUrl ? invoiceUrl.split("/").pop() : "-";
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{invoiceName}</p>
            </div>
          );
        case "Invoice_Type__c":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Invoice_Type__c"]}
              </p>
            </div>
          );
        case "Invoice_Status__c":
          return renderStatus(item);
        case "Invoice_Remarks__c":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item["Invoice_Remarks__c"]}
              </p>
            </div>
          );

        case "action":
          return <VendorInvoiceAction item={item} />;
      }
    },
    [renderStatus, sortedItems],
  );

  const topContent = React.useMemo(() => {
    return (
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end my-2">
          <Input
            isClearable
            className="focus:outline-none focus:border-none"
            classNames={{
              base: "w-full sm:max-w-[44%] focus:outline-none focus:border-none",
              inputWrapper:
                "border-0 focus:border-0 focus:outline-none focus:border-none",
              input: "border-0 focus:outline-none focus:border-none",
            }}
            placeholder="Search by po or invoice number..."
            size="sm"
            startContent={<CiSearch />}
            value={filterValue}
            onClear={() => {
              setFilterValue("");
              setSelectedState("all");
            }}
            onValueChange={onSearchChange}
          />
          <Button
            color="default"
            variant="bordered"
            className="font-roboto"
            size="sm"
            radius="sm"
            onClick={navigateToUploadinvoiceDetails}
          >
            Upload Invoices
          </Button>
        </div>
        {showFilter && (
          <div className="absolute top-full mt-2 right-0 w-[20%] px-6 py-4 rounded shadow-xl z-10 bg-white">
            <p className="text-black text-base leading-8 font-roboto font-medium mt-[4%] bg-white">
              Filter
            </p>
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownFilter}
              label="State"
              placeholder="State"
              onSelect={handleStateSelect}
              className="font-roboto text-xl font-light"
            />
            <div>
              <Row>
                <Button
                  color="default"
                  className="font-roboto font-light text-sm bg-buttonprimary text-white w-[100%] mt-4"
                  size="md"
                  radius="sm"
                  onClick={() => {
                    setSelectedState(tempSelectedState);
                    setShowFilter(false);
                  }}
                >
                  Filter
                </Button>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    filterValue,
    onSearchChange,
    navigateToUploadinvoiceDetails,
    showFilter,
    dropdownFilter,
    tempSelectedState,
  ]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page:&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  return (
    <div className="flex flex-col">
      <Table
        selectionMode="single"
        classNames={classNames}
        topContent={topContent}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item.Invoice_PDF_URL__c}`}
              className="cursor-pointer h-12"
            >
              {(columnKey) => (
                <TableCell className=" text-center ">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
